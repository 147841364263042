import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Mireille CANABY Avocat', // e.g: 'Name | Developer'
  lang: 'fr', // e.g: en, es, fr, jp
  description:
    'Maître Mireille CANABY Avocat Perpignan. Spécialiste en droit de la famille, des personnes et de leur patrimoine.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Maître',
  name: 'Mireille CANABY',
  subtitle: 'Spécialiste en droit de la famille, des personnes et de leur patrimoine',
  punchline: 'Chaque dossier est unique et doit être défendu comme tel.',
  cta: "Plus d'informations",
  ctaContact: 'Me contacter',
};

// ABOUT DATA
export const aboutData = {
  img: 'mireille.jpg',
  presentation: `Après un cursus à l’université de Perpignan, puis au centre de formation
                 des avocats du Barreau de Bruxelles, Me CANABY a prêté serment le 8 mai 1995,
                 son inscription au Barreau de Bruxelles date de la même année.<br />
                 Elle a intégré le Barreau des Pyrénées Orientales à compter de l’année 2001,
                 et a rejoint le cabinet <strong>RAYNAUD ET ASSOCIES</strong> au mois de septembre 2007.
                 Elle a exercé en SCP puis en exercice individuel.<br />
                 Me CANABY a obtenu son <strong>CERTIFICAT DE SPECIALISATION</strong> en <strong>droit de la famille</strong>
                 des personnes et de leur patrimoine. Elle est également dîplomée de <strong>droit collaboratif</strong>.`,
};

// DOMAINS DATA
export const domainsData = {
  description: 'Me CANABY vous accompagne dans les domaines suivants:',
  domainsArray: [
    {
      name: 'Adoption',
      description: 'adoption simple, adoption plénière ou adoption internationale',
    },
    {
      name: 'Concubinage - union libre',
      description:
        'contrat de concubinage, liquidation, indivision, partage des biens, jouissance du logement familial ',
    },
    {
      name: 'Dépendance',
      description: 'majeurs protégés, tutelle, abus de faiblesse, mandat de protection future',
    },
    {
      name: 'Divorce',
      description:
        'sans juge, accepté, pour faute,  altération définitive, divorce judiciaire, pension alimentaire entre époux, prestation compensatoire',
    },
    {
      name: 'Enfant',
      description:
        "autorité parentale, délégation de l'autorité parentale, audition de l'enfant, droits de l'enfant, droit de visite et hébergement, garde, résidence des enfants, résidence alternée",
    },
    {
      name: 'Enfance en danger',
      description:
        "assistance éducative, non présentation d'enfant, enlèvement, harcelèment scolaire",
    },
    {
      name: 'Etat civil',
      description: 'Changement de nom ou de sexe',
    },
    {
      name: "Enlèvement international d'enfant",
      description: '',
    },
    {
      name: 'Filiation',
      description: "recherche de parternité, reconnaissance enfant, possession d'état, expertise",
    },
    {
      name: 'Fiscalité de la famille',
      description:
        'droits de partage, partage verbal, quotient familial, droits de donation ou succession, résidence alternée',
    },
    {
      name: 'Grands-parents',
      description: 'droits des grand-parents et des tiers',
    },
    {
      name: 'Indivision',
      description:
        "partage de l'indivision, maintien dans l'indivision, indemnité d'occupation, autorisation d'effectuer des travaux, autorisation judiciaire de vente",
    },
    {
      name: 'Régime matrimonial',
      description:
        "choix du régime, changement de régime matrimonial, avantages matrimoniaux, mariage à l'étranger, loi applicable au partage des biens, liquidation du régime matrimonial, recel de biens",
    },
    {
      name: 'PMA-GPA',
      description: 'don ovocytes, mère porteuse, procréation médicalement assistée, adoption',
    },
    {
      name: 'Pension alimentaire',
      description: 'enfants, conjoint, parent placé',
    },
    {
      name: 'Prestation compensatoire',
      description: 'conditions, montant, durée, révision, suppression, en cas de décès',
    },
    {
      name: 'Mariage',
      description:
        'fiançailles, contrat de mariage, pacte de famille pour organiser la séparation de fait, contribution aux charges du mariage, annulation du mariage',
    },
    {
      name: 'Libéralités',
      description:
        'avantages matrimoniaux, assurances vie, testament, donation simple, donation partage, donationtransgénérationnelle',
    },
    {
      name: 'Litiges internationaux',
      description: 'loi applicable, juge compétent',
    },
    {
      name: 'PACS',
      description:
        'contribution aux charges communes, déclaration de revenus, partage des biens, rupture de PACS',
    },
    {
      name: 'Partage des biens',
      description:
        'avec ou sans biens immobilier, droits de partage, liquidation anticipée du partage, attribution préférentielle, liquidation du régime matrimonial',
    },
    {
      name: 'Succession',
      description:
        'décès, organisation des funérailles, héritage, contestation, annulation ou révocation du testament, recel successoral, legs, atteinte à la réserve, droits des héritiers, réintégrations des donations, abus de faiblesse',
    },
    {
      name: 'Transmission du patrimoine',
      description:
        'assurance vie, régime matrimonial, avantage matrimonial, SCI, tontine, fiducie, démembrement propriété',
    },
    {
      name: 'Violences conjuguales',
      description:
        'ordonnace de protection, éloignement du conjoint violent, mesures de protection pour les enfants, attribution du logement familial',
    },
  ],
};

// OUR VALUES DATA
export const ourValuesData = [
  {
    type: 'quote',
    title: '',
    content:
      '"Je jure comme avocat, d’exercer mes fonctions avec dignité, conscience, indépendance, probité et humanité"',
  },
  {
    type: '',
    title: '',
    content: `En accord avec ce serment, chaque intervention réalisée pour le compte de la clientèle est nécessairement animée par des valeurs de disponibilité, de communication, de rigueur
              et de transparence dans le respect des règles déontologiques régissant la profession d’avocat.`,
  },
  {
    type: '',
    title: 'Disponibilité:',
    content: `Maître CANABY accompagne sa clientèle dans son processus décisionnel et la mise en œuvre
              de ses projets, en lui apportant son expertise juridique mais également assistance et
              représentation devant les juridictions concernées.
              Accessible et demeurant à l’écoute de ses clients, le cabinet vous communiquera toute
              nouvelle information concernant l’évolution de votre dossier, explications à l’appui. `,
  },
  {
    type: '',
    title: 'Communication:',
    content: `L’écoute de la clientèle constitue ici une priorité. Un dialogue constant est donc
              favorisé permettant ainsi de délimiter avec précision vos diverses attentes.
              A toute fin utile, chaque projet est systématiquement adressé au client aux fins
              d’obtenir approbation de la stratégie retenue. Sauf extrême urgence empêchant le respect
              de cette procédure, aucune écriture n’est adressée à la juridiction concernée sans avoir
              fait préalablement l’objet d’un échange.
              Concernant les écritures adverses, celles-ci sont immédiatement adressées au client.
              De même, la notification des décisions de justice s’accompagne d’une lettre explicative
              claire et précise en vue de se prononcer, le cas échéant, sur l’opportunité d’exercer
              des voies de recours.  `,
  },
  {
    type: '',
    title: 'Rigueur:',
    content: `L’un des objectifs principaux de votre avocat consiste à élaborer avec
              son client une stratégie prenant en considération ses attentes mais
              aussi les éléments réalisables, tant de fait que de droit.
              Le cabinet s’attache ainsi à donner une information la plus complète
              possible mais également la plus claire et accessible sur les divers
              tenants et les aboutissants d’une procédure. Systématiquement,
              il sera procédé à une évaluation des risques permettant ainsi une meilleure
              compréhension des difficultés rencontrées par le client.`,
  },
  {
    type: '',
    title: 'Transparence:',
    content: `Les honoraires sont fixés préalablement dès le premier rendez-vous dans
              la transparence la plus totale. Il est également offert la possibilité
              aux particuliers de procéder à des règlements échelonnés, en fonction de
              l’avancement des procédures et de la réalisation des diligences.
              Réactivité à l’égard de vos besoins, clarté dans les réponses qui vous
              seront apportées et disponibilité constituent en définitive les maître
              mots de chaque intervention.`,
  },
];

// FEES DATA
export const feesData = [
  {
    title: '',
    content: `Le Cabinet de Maître CANABY établit ses honoraires en toute transparence
             avec ses clients, selon la nature et la difficulté de l’affaire et du
             temps consacré au traitement du dossier.`,
  },
  {
    title: '',
    content: `Trois types de facturation pourront vous être proposées selon la
              nature de l’intervention ou de la procédure engagée : `,
  },
  {
    title: "L'honoraire forfaitaire",
    content: `Cette formule est généralement utilisée dans le cas où il n’existe que peu d’aléa concernant
              le déroulement de la procédure, permettant donc au cabinet de convenir avec son client d’un
              montant forfaitaire pour l’ensemble du traitement du dossier sans considération du temps passé.`,
  },
  {
    title: "L'honoraire de résultat",
    content: `Cet honoraire, qui est fonction du résultat obtenu
    (par exemple : le montant d’une somme allouée devant une juridiction) ne peut
    pas légalement constituer la seule rémunération de l’avocat.
    Il est donc nécessaire de déterminer un honoraire fixe, qui, en fonction du résultat obtenu,
    sera ou non complété par le versement d’un honoraire complémentaire,
    qui constitue un pourcentage des gains obtenus ou économisés par le client.`,
  },
  {
    title: 'Les honoraires au temps passé',
    content: `Les honoraires seront basés sur le nombre d’heures consacrées au
              traitement du dossier par le cabinet. Le montant des honoraires
              sera donc fonction à la fois du temps passé et du taux horaire.
              Ces honoraires concernent principalement les dossiers complexes
              ou nécessitant un temps de travail difficile à déterminer par avance.`,
  },
];

// CONTACT DATA
export const contactData = {
  email: 'contact@canaby-avocat-perpignan.fr',
  address: 'Me Mireille Canaby <br/> 19 espace Méditéranée,<br/>66000 Perpignan',
  socials: [
    {
      name: `phone`,
      username: `+33 4 68 22 35 14`,
      url: `tel:`,
    },
    {
      name: `linkedin`,
      username: `mireille-canaby-388b2036`,
      url: `https://linkedin.com/in/`,
    },
    {
      name: `facebook`,
      username: `mireille.canaby.79`,
      url: `https://facebook.com/`,
    },
  ],
};
